
$fa-font-path: './fonts/font-awesome' !default;
$body-font-family: 'Open Sans', sans-serif !default;

@import "./bower_components/foundation/scss/foundation";
@import "./bower_components/bourbon/app/assets/stylesheets/bourbon";
@import "./bower_components/font-awesome/scss/font-awesome";

$blue: #1c74af;
$light-blue: #6cabd0;
$gray: #8a8a8a;
$light-gray: #EFEFEF;

@import "modules/create";
@import "modules/loading";
@import "modules/nope";