.global-nope {
  top: 0;
  bottom:0;
  left:0;
  right: 0;
  background: $blue;
  position: absolute;

  .nope-center {
    position: absolute;
    top: 30%;
    left: 50%;
    margin: -30px 0 0 -100px;
    width: 200px;
    text-align: center;
    color: #fff;
  }
  a {
    display: block;
    width: 60px;
    height: 60px;
    margin: 40px auto 15px;
    background-size: 60px;
    background-image: url('../images/logo.svg');
  }
}