@keyframes spin {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(-45deg); }
	50% { transform: rotate(405deg); }
  60% { transform: rotate(360deg); }
  100% { transform: rotate(360deg); }
}

.global-loading {
  top: 0;
  bottom:0;
  left:0;
  right: 0;
  background: $blue;
  position: absolute;
  span {
    display: block;
    width: 60px;
    height: 60px;
    margin: 40px auto 15px;
    background-size: 60px;
    background-image: url('../images/logo.svg');
    position: absolute;
    top: 30%;
    left: 50%;
    margin: -30px 0 0 -30px;
    animation-name: spin;
    animation-duration: 2s; /* 3 seconds */
    animation-iteration-count: infinite;
    animation-timing-function: linear;

  }

}