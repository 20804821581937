
// Base layout.
.create-grid {
  font-size: 12px;
  .header {

  }

  .button {
    z-index: 0;
  }

  .grid {
    position: absolute;
    &.grid-menu {
      @include user-select(none);
      width: 200px;
      top:  0;
      left: 0;
      bottom: 0;
      background: $blue;
      overflow: scroll;
      color: #fff;
      .logo {
        margin-bottom: 50px;
        h1 {
          font-size: 24px;
          color: #fff;
          text-align: center;
          text-transform: uppercase;
          font-weight: 100;
        }
        i {
          display: block;
          width: 60px;
          height: 60px;
          margin: 40px auto 15px;
          background-size: 60px;
          background-image: url('../images/logo.svg');
        }
        a {
          color: #fff;
          display: block;
          text-align: center;
          &.powered {
            font-style: italic;
          }
        }
      }
      .grid-action {
        color: #fff;
        display: block;
        width: 150px;
        border: 1px solid rgba(255,255,255, 0.8);
        text-align: center;
        margin: 20px auto;
        padding: 15px;
        i {
          display: block;
          font-size: 24px;
          margin-bottom: 2px;
        }
        span {
          font-weight: 100;
        }

        &.active {
          border-color: #fff;
          box-shadow: 0 0px 15px rgba(255,255,255, 0.8), inset 0px 0px 15px rgba(255,255,255, 0.8);
        }
        &.inactive {
          color: rgba(255,255,255, 0.6);
          border-color: rgba(255,255,255, 0.6);
        }
      }
      .add-resources {
        margin-top: 40px;
        padding: 0 20px;

        h4 {
          color: #fff;
          font-size: 14px;
          margin-top: 20px;
        }

        .add {
          input {
            height: 25px;
            font-size: 12px;
            padding: 3px;
          }
          a {
            color: #fff;
            text-align: center;
            display: block;
            &.add-button {
              font-size: 20px;
              margin: 0 auto;
              line-height: 24px;
            }
          }
        }
        .grid-sources-overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(0,0,0,0.7);
          z-index: 5;
          padding: 30px 10px;
          overflow: scroll;
          .grid-sources-content {
            background: #fff;
            border-radius: 3px;
            padding: 10px 0;
            ul {
              list-style: none;
              margin-left: 0;
              a {
                display: block;
                padding: 5px 10px;
                font-size: 12px;
                color: $blue;
                span {
                  float: right;
                  display: none;
                }
                &:hover {
                  span {
                    display: block;
                  }
                }
              }
            }
            a.close {
              position: absolute;
              top: 5px;
              right: 5px;
              color: #fff;
              font-size: 20px;
              line-height: 15px;

            }
          }
        }

        .grid-resource {
          .ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
          }
          a {
            color: #fff;
            &.remove {
              display: block;
              text-align: center;

            }
          }
        }


      }
    }

    &.grid-left {
      left: 200px;;
    }

    &.grid-right {
      border-left: 1px solid $gray;
    }

  }
  .vertical-breaker {
    position: absolute;
    width: 10px;
    margin-left: -5px;
    z-index:20;
    cursor: col-resize;
  }

  .horizontal-breaker {
    position: absolute;
    height: 20px;
    //margin-top: -5px;
    z-index: 19;
    cursor:row-resize;
  }
}
.grid-sub {
  position: absolute;

  &.grid-html {
  }
  &.grid-css {
  }
  &.grid-javascript {
  }

  .grid-sub-header {
    @include user-select(none);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    z-index: 1;
    background: $light-blue;
    .grid-title {
      font-size: 12px;
      line-height: 20px;
      color: #fff;
      padding-left: 20px;
      display: block;

    }
  }

  .grid-editor {

  }
  .ace_editor {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    bottom: 0;
    &.ace-xcode {
      .ace_marker-layer .ace_active-line {
        background: transparent;
      }
      .ace_gutter {
        background: #edf5f9;
      }
      .ace_gutter-active-line {
        background: #b1d4e7;
      }
    }
  }
}

a.grid-toggle {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  height: 20px;
  width: 20px;
  z-index:20;
  padding: 4px 0 0 4px;
  color: #fff;
  i {
    z-index: 20;
    &.fa-angle-up {
      display: block;
    }
    &.fa-angle-down {
      display: none;
    }
  }

  .inactive  & {
    i {
      &.fa-angle-up {
        display: none;
      }
      &.fa-angle-down {
        display: block;
      }
    }
  }
}

.grid-actions {
  border-bottom: 1px solid #222;
  padding: 10px;
  @include user-select(none);
}

.grid-wrapper {
  background: $light-gray;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  padding: 0 50px;
  h4 {
    color: $gray;
    font-size: 14px;
    height: 40px;
    padding: 10px 0;
    @include user-select(none);
  }
  .grid-preview {
    iframe {
      margin: 0 auto;
      display: block;
      max-width: 700px;
      width: 100%;
      @include user-select(none);
    }
  }
  .grid-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    display: none;
    @include user-select(none);
  }
}

.grid-embed-width-sizing {
  display: none;
  height: 20px;
  padding: 0;
  @include user-select(none);

  .grid-embed-width-size {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    border-right: 1px solid #222;
    border-left: 1px solid #222;
    hr {
      margin: -10px 0 10px;
      padding: 0 0 10px 0;
      border-color: #222;
    }
    span {
      background: $light-gray;
      z-index: 6;
      display: inline-block;
      padding: 0 5px;
    }
  }
}

.grid-embed-height-sizing {
  display: none;
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  width: 20px;
  @include user-select(none);

  .grid-embed-height-size {
    width: 20px;
    text-align: center;
    position: relative;
    border-top: 1px solid #222;
    border-bottom: 1px solid #222;
    hr {
      margin: 0 0 0 10px;
      padding: 0 0 10px 0;
      border-color: #222;
      height: 100%;
      width: 0;
      border-width: 0 1px 0 0;
    }
    span {
      position: absolute;
      background: $light-gray;
      z-index: 6;
      display: inline-block;
      padding: 0;
      top: 50%;
      left: 0;
      margin-top: -10px;
    }
  }
}

.grid-copy-drawer {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 30px;
  overflow: hidden;
  z-index: 10;
  a {
    color: $blue;
  }
  .drawer-header {
    height: 30px;
    background: #fff;
    h4 {
      margin:0;
      padding:5px;
    }
  }
  .drawer-content {
    padding: 20px;
    background: #fff;
  }
  .close {
    display: none;
    position: absolute;
    top: 2px;
    right: 10px;
    font-size: 20px;
  }

  &.active {
    .close {
      display: block;
    }
  }



}





